@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap&family=Teko:wght@300..700&display=swap&family=Cairo:wght@300..700&display=swap");
html,
body {
  overflow: auto;
  padding: 0;
  margin: 0;
  height: 100%;
}
body {
  display: flex;
  justify-content: center;
  align-items: center;
}
#root {
  width: 100%;
}
marquee {
  width: 100%;
}
section.home div.feedback {
  display: flex;
  justify-content: center;
  align-items: center;
}
section.home div.feedback div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: 0 50px;
  margin: 0 50px;
}
section.home div.feedback div h3 {
  font-family: Cairo;
  padding: 40px 10px;
  background-color: #ffc0867c;
  text-align: center;
}
section.home div {
  text-align: center;
}
section.home div h1 {
  display: inline;
  /* font-size: 3rem; */
  font-family: sans-serif;
  font-style: italic;
  letter-spacing: 10px;
  line-height: 120px;
  text-align: center;
}
section.home div h2 {
  display: inline;
  font-weight: lighter;
  font-family: monospace;
  text-align: center;
}

/* feedback  */

section.feedback {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
section.feedback h1 {
  /* font-weight: regular; */
  font-family: "Teko";
  text-align: center;
}
section.feedback h1 span {
  font-weight: bold;
}
section.feedback div {
  display: flex;
  justify-content: center;
  align-items: center;
}
section.feedback div input {
  outline: none;
  border: none;
  font-family: Cairo;
  height: 100px;
  background-color: #ffc0867c;
  text-align: center;
}
section.feedback button {
  outline: none;
  border: none;
  width: 50%;
  height: 50px;
  font-size: 25px;
  font: "Teko";
  font-weight: bold;
}
